import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';

function Footer() {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    const { formatMessage } = useIntl();

    return (
        <div style={{
            padding: (isSM || isXS) ? "48px" : "48px 200px",
            backgroundColor: "#7001F6"
        }} >

            <img src="/assets/images/footer-logo.svg" alt="footer logo" style={{ cursor: "pointer" }} onClick={() => window.location.href = "/"} />

            <div
                style={{
                    display: "flex",
                    flexDirection: isXS ? "column" : "row",
                    flexWrap: (isXL || isLG) ? "nowrap" : "wrap",
                    gap: (isSM || isXS) ? "30px" : "90px",
                    alignItems: (isSM || isXS) ? "start" : "center",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "500",
                    marginTop: "36px",
                    justifyContent: "space-between",
                }}>

                <FooterItemWrapper>
                    <p style={{
                        fontWeight: "700",
                        margin: 0
                    }}>
                        <FormattedMessage id="owajoo_technology_inc" />
                    </p>
                    <FooterItem text="123 Street Name, City Name, Country Name" icon={<img src="/assets/images/location.png" alt="location" height={"24px"} width={"24px"} />} />
                    <FooterItem text={formatMessage({ id: "phone" }) + ": +123 456 7890"} icon={<img src="/assets/images/phone.png" alt="phone" height={"24px"} width={"24px"} />} />
                    <FooterItem text={formatMessage({ id: "email_address" }) + ": info@owajoo.com"} icon={<img src="/assets/images/email.png" alt="email" height={"24px"} width={"24px"} />} />
                </FooterItemWrapper>
                <FooterItemWrapper>
                    <FooterItem text={<FormattedMessage id='contact_us' />} link="/contact" />
                    <FooterItem text={<FormattedMessage id='faqs' />} link="/faqs" />
                    <FooterItem text={<FormattedMessage id='privacy_policy' />} link="/privacy-policy" />
                    <FooterItem text={<FormattedMessage id='user_agreement' />} link="/user-agreement" />
                </FooterItemWrapper>
                <FooterItemWrapper>
                    <FooterItem text="owajoo" link="https://www.instagram.com" icon={<img src="/assets/images/instagram.png" alt="instagram" height={"24px"} width={"24px"} />} />
                    <FooterItem text="owajoo" link="https://www.facebook.com" icon={<img src="/assets/images/facebook.png" alt="facebook" height={"24px"} width={"24px"} />} />
                    <FooterItem text="owajoofficial" link={"https://www.x.com"} icon={<img src="/assets/images/x.png" alt="x" height={"24px"} width={"24px"} />} />
                </FooterItemWrapper>
                <FooterItemWrapper>
                    <img src="/assets/images/app-store-badge.png" alt="app-store-badge" style={{ height: "50px", objectFit: "contain", cursor: "pointer" }} />
                    <img src="/assets/images/google-play-store-badge.png" alt="google-play-store-badge" style={{ height: "50px", objectFit: "contain", cursor: "pointer" }} />
                </FooterItemWrapper>
                <FooterItemWrapper>
                    <img src="/assets/images/qr.png" alt="qr" style={{ height: "100px", objectFit: "contain" }} />
                </FooterItemWrapper>
            </div>

        </div>
    )
}

function FooterItemWrapper({ children }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px"
            }}
        >
            {children}
        </div>
    )
}

function FooterItem({ text, link, icon }) {
    const TextComponent = link ? Link : "p"
    return (
        <div style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            ...(link?.startsWith("http") ? { cursor: "pointer" } : {})
        }}
            onClick={() => {
                if (link?.startsWith("http")) {
                    window.open(link, "_blank")
                }
            }}
        >
            {!!icon && icon}
            <TextComponent {...(link ? {
                to: link,
                target: link?.startsWith("http") ? "_blank" : "",
                onClick: () => {
                    if (!(link?.startsWith("http"))) {
                        window.scrollTo(0, 0)
                    }
                }
            } : {})}
                style={{
                    margin: 0,
                    textDecoration: "none",
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "500",
                }}
            >{text}</TextComponent>
        </div>
    )
}

export default Footer