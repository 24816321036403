import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive'

function FAQsPage({ language }) {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    const [faqs, setFaqs] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}faq/getFaqs?language=${language}`)
            .catch((err) => {
                console.log("err: " + err);
                setFaqs([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setFaqs(response.data.faqs);
                }
            });
    }, [language]);

    return (
        <div style={{
            padding: (isSM || isXS) ? "30px 50px 75px 50px" : "30px 180px 75px 180px",
            backgroundColor: "white",
            color: "#212121",
            fontSize: "16px"
        }}>
            <h1 style={{
                textAlign: "center",
                fontWeight: "700",
                fontSize: "40px",
            }}>
                <FormattedMessage id='faq_title' />
            </h1>
            <div style={{
                marginTop: "36px",
            }}>
                {faqs.map((faq, index) => {
                    return <FAQItem key={index} question={faq.title} answer={faq.answer} />
                })}
            </div>
        </div>
    )
}

function FAQItem({ question, answer }) {
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div style={{
            borderRadius: "5px",
            margin: "auto auto 10px auto",
            padding: "24px",
            paddingBottom: "10px",
            maxWidth: "950px",
            backgroundColor: "#F5F5F5",
            border: "1px solid #DADADA",
        }}>
            <div
                style={{
                    cursor: "pointer",
                    fontSize: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: isXS ? "start" : "center",
                    gap: "10px",
                }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <h3 style={{ margin: "0", fontWeight: "700" }}>{question}</h3>
                <h3 style={{ margin: "0", fontWeight: "700" }}>{isExpanded ? "-" : "+"}</h3>
            </div>
            <div style={{
                maxHeight: isExpanded ? "1000px" : 0,
                overflow: "hidden",
                transitionProperty: "max-height",
                transitionDuration: "0.5s",
                marginTop: "12px",
                fontSize: "16px",
            }}>
                <p style={{
                    margin: "0"
                }}>{answer}</p>
            </div>
        </div>
    )
}


export default FAQsPage