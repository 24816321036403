export default function formatDate(inputDate, returnFullDate = false) {
    const currentDate = new Date();
    const date = new Date(inputDate);

    const diffInMilliseconds = currentDate - date;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (!returnFullDate) {
        if (diffInMinutes < 1) {
            return 'now';
        } else if (diffInMinutes < 60) {
            return `${diffInMinutes} minutes ago`;
        } else if (diffInHours < 24) {
            return `${diffInHours} hours ago`;
        } else if (diffInDays < 30) {
            return `${diffInDays} days ago`;
        }
    }
    // Format the date in "dd.MM.yyyy" format with dots as separators
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-UK', options).replace(/\//g, '.');
}