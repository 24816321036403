import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMediaQuery } from 'react-responsive'

function ContactPage() {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    const { formatMessage } = useIntl();
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        onDrop: (acceptedFile) => {
            console.log(acceptedFile)
            setUploadedFile(acceptedFile)
        }
    });
    const [uploadedFile, setUploadedFile] = useState(null);


    const handleFormSubmit = async (e) => {
        //upload files if exists use axios (contactMessage/uploadContactMessageContent)
        //send form data to (contactMessage/createContactMessage)
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            fullname: formData.get('name'),
            email: formData.get('email'),
            title: formData.get('title'),
            message: formData.get('message'),
            sent_from: "website"
        }
        if (uploadedFile?.length > 0) {
            const contactMessageContent = uploadedFile[0];
            await axios.post(`${process.env.REACT_APP_API_URL}contactMessage/uploadContactMessageContent`, {
                contactMessageContent: contactMessageContent
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    data.content_path = response.data.contentPath;
                }
            });
        }
        await axios.post(`${process.env.REACT_APP_API_URL}contactMessage/createContactMessage`, {
            ...data
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    //reset form
                    e.target.reset();
                    setUploadedFile(null);
                }
            });
    }

    return (
        <div>
            {(isXL || isLG || isMD) &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        padding: "97px 0 97px 0",
                        backgroundImage: "url('assets/images/background.png')",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        backgroundSize: "cover",
                    }}>
                    <h1 style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: "40px",
                        color: "white",
                        marginTop: 0
                    }}>
                        <FormattedMessage id="contact_us" />
                    </h1>
                </div>
            }
            <div style={{
                padding: (isSM || isXS) ? "70px 50px" : "100px 180px",
                backgroundColor: "white",
                color: "#212121"
            }}>
                <h1 style={{
                    textAlign: "center",
                    fontWeight: "700",
                    fontSize: "40px",
                    marginTop: 0
                }}>
                    <FormattedMessage id="leave_us_a_message" />
                </h1>
                <div>
                    <form
                        onSubmit={handleFormSubmit}
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                            maxWidth: "860px",
                            margin: "auto",
                            gap: "20px"
                        }}>
                        <InputField
                            inputProps={{
                                id: "name",
                                type: "text",
                                placeholder: formatMessage({ id: "full_name" }),
                                required: true,
                            }}
                            containerProps={{
                                gridColumn: (isSM || isXS) ? "span 2" : "span 1",
                            }}
                        />
                        <InputField
                            inputProps={{
                                id: "email",
                                type: "email",
                                placeholder: formatMessage({ id: "email" }),
                                required: true,
                            }}
                            containerProps={{
                                gridColumn: (isSM || isXS) ? "span 2" : "span 1",
                            }}
                        />

                        <InputField
                            inputProps={{
                                id: "title",
                                type: "text",
                                placeholder: formatMessage({ id: "subject" }),
                                required: true,
                            }}
                            containerProps={{
                                gridColumn: "span 2",
                            }}
                        />

                        <InputField
                            inputProps={{
                                id: "message",
                                type: "text",
                                placeholder: formatMessage({ id: "message" }),
                                required: true,
                                rows: 4
                            }}
                            containerProps={{
                                gridColumn: "span 2",
                            }}
                            textarea
                        />

                        <div style={{
                            gridColumn: "span 2",
                            display: "flex",
                            flexDirection: "column",
                            gap: "12px",
                            fontSize: "14px",
                            fontWeight: "600",
                        }}>
                            <div {...getRootProps({ className: "dropzone" })} style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                                padding: "70px 20px",
                                backgroundColor: "#F2EBFF",
                                border: "2px dashed #8C52FF",
                                borderRadius: "12px",
                                cursor: "pointer"
                            }}>
                                <input {...getInputProps()} />
                                <p style={{ margin: 0, color: "#8C52FF", textAlign: "center" }}>
                                    <FormattedMessage id="clict_to_select_file_optional" />
                                </p>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "12px",
                            }}>
                                {!!uploadedFile &&
                                    <div style={{
                                        display: "flex",
                                        gap: "12px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        padding: "8px 16px",
                                        backgroundColor: "#1C1C1C10",
                                        borderRadius: "12px"
                                    }}>
                                        <p style={{ margin: 0, color: "#8C52FF", userSelect: "none" }}>{uploadedFile[0].name}</p>
                                        <img src="/assets/images/close.png" alt="close" style={{ height: "24px", cursor: "pointer", userSelect: "none" }} onClick={() => {
                                            setUploadedFile(null)
                                        }} />
                                    </div>

                                }
                            </div>
                        </div>

                        <button style={{
                            background: "#8C52FF",
                            padding: "16px 24px",
                            textAlign: "center",
                            border: "none",
                            gridColumn: "span 2"
                        }} className={'rounded16 app-button'} >
                            <p style={{ fontSize: "18px", margin: 0, color: "white", userSelect: "none" }}>
                                <FormattedMessage id="send" />
                            </p>
                        </button>

                    </form>
                </div>
            </div>
        </div>
    )
}

function InputField({ containerProps, inputProps, textarea }) {
    const { formatMessage } = useIntl();
    const Input = textarea ? 'textarea' : 'input';
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '12px',
                fontSize: '14px',
                fontWeight: '600',
                ...(containerProps ? containerProps : {})
            }}
        >
            <Input
                {...inputProps}
                name={inputProps?.name || inputProps?.id}
                onInvalid={(e) => {
                    if (e.target.validity.valueMissing) {
                        e.target.setCustomValidity(formatMessage({ id: "this_field_cannot_be_empty" }));
                    } else if (e.target.validity.typeMismatch || (inputProps?.type === 'email' && e.target.validity.patternMismatch)) {
                        e.target.setCustomValidity(formatMessage({ id: "please_enter_a_valid_email" }));
                    } else {
                        e.target.setCustomValidity("");
                    }
                }}
                onChange={(e) => {
                    inputProps?.onChange && inputProps.onChange(e);
                    if (e.target.validity.valueMissing) {
                        e.target.setCustomValidity(formatMessage({ id: "this_field_cannot_be_empty" }));
                    } else if (e.target.validity.typeMismatch || (inputProps?.type === 'email' && e.target.validity.patternMismatch)) {
                        e.target.setCustomValidity(formatMessage({ id: "please_enter_a_valid_email" }));
                    } else {
                        e.target.setCustomValidity("");
                    }
                }}
                style={{
                    borderColor: '#1C1C1C10',
                    backgroundColor: 'white',
                    borderWidth: '2px',
                    borderRadius: '12px',
                    padding: '16px 20px',
                    fontSize: '14px',
                    fontWeight: '600',
                    placeholderTextColor: '#9ca3af',
                }}
            />
        </div>
    );


}


export default ContactPage