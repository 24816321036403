import { Route, Routes } from "react-router-dom";
import Header from "./global/Header";
import MainPage from "./pages/MainPage";
import AdvertisementPage from "./pages/AdvertisementPage";
import ServiceProviderPage from "./pages/ServiceProviderPage";
import AboutUsPage from "./pages/AboutUsPage";
import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./global/Footer";
import UserAgreement from "./pages/UserAgreement";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FAQsPage from "./pages/FAQsPage";
import ContactPage from "./pages/ContactPage";
import { FormattedMessage, IntlProvider } from "react-intl";
import Deutch from "./lang/de.json";
import English from "./lang/en.json";

function App() {
  const [settings, setSettings] = useState(null);
  const [locale, setLocale] = useState(localStorage.getItem("locale") || "de");

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}settings/getSettings`)
      .catch((err) => {
        console.log("err: " + err);
        setSettings(null);
      })
      .then((response) => {
        if (response && response.data.success === true && response.data.status === 200) {
          setSettings(response.data.settings);
        }
      });
  }, []);
  return (
    <>
      <IntlProvider locale={locale} messages={locale === "de" ? Deutch : English}>
        <Header language={locale} onLanguageChange={(language) => {
          setLocale(language);
          localStorage.setItem("locale", language);
        }} />
        <Routes>
          <Route exact path="/advertisement/:advertisementId" element={<AdvertisementPage language={locale} />} />
          <Route exact path="/user/:serviceProviderId" element={<ServiceProviderPage language={locale} />} />
          <Route exact path="/" element={<MainPage />} />
          <Route exact path="/about-us" element={<AboutUsPage settings={settings} language={locale} />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy settings={settings} />} />
          <Route exact path="/user-agreement" element={<UserAgreement settings={settings} />} />
          <Route exact path="/faq" element={<FAQsPage language={locale} />} />
          <Route exact path="/contact" element={<ContactPage />} />
          <Route path="*" element={
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "65vh" }}>
              <h1 style={{ fontSize: "100px", fontWeight: 900, margin: 0, color: "#8C52FF" }}>404</h1>
              <h2 style={{ fontSize: "24px", fontWeight: 900, margin: 0 }}>
                <FormattedMessage id="page_not_found" />
              </h2>
            </div>
          } />
        </Routes>
        <Footer />
      </IntlProvider>
    </>
  );
}

export default App;
