import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import CustomSelect from '../UI/CustomSelect';
import { FormattedMessage } from 'react-intl';

function Header({ language, onLanguageChange }) {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    const [selectedLanguage, setSelectedLanguage] = useState(language === "de" ? { img: "/assets/images/germany.png", value: "de" } : { img: "/assets/images/united-kingdom.png", value: "en" })
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const handleMenuClose = (withScroll) => {
        if (withScroll) {
            window.scrollTo(0, 0);
        }
        if (isMD || isSM || isXS) {
            setIsMenuOpen(false)
        }
    }

    return (
        <div style={{
            padding: (isXS) ? "16px 32px" : "16px 96px",
            backgroundColor: "white",
            position: "sticky",
            top: 0,
            zIndex: 1,
            border: "1px solid #E5E5E5",
            display: "flex",
            flexDirection: (isMD || isSM || isXS) ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center"
        }} >

            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", ...((isMD || isSM || isXS) ? { width: "100%" } : {}) }}>
                <img src="/assets/images/header-logo.svg" alt="header logo" style={{ cursor: "pointer" }} onClick={() => window.location.href = "/"} />
                {(isMD || isSM || isXS) &&
                    <img
                        src="/assets/images/menu.png"
                        alt="menu"
                        width={"32px"}
                        height={"32px"}
                        style={{
                            cursor: "pointer",
                        }}
                        onClick={() => setIsMenuOpen((prev) => !prev)}
                    />
                }
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: (isMD || isSM || isXS) ? "column" : "row",
                    gap: (isMD || isSM || isXS) ? "16px" : "32px",
                    alignItems: "center",
                    color: "#000000",
                    fontSize: (isMD || isSM || isXS) ? "22px" : "16px",
                    backgroundColor: "white",
                    fontWeight: "600",
                    overflow: "hidden",
                    transition: "all 0.5s",
                    ...((isMD || isSM || isXS) && isMenuOpen ? {
                        marginTop: "30px",
                    } : {}),
                    ...((isMD || isSM || isXS) ? (isMenuOpen ? { maxHeight: "1000px" } : { maxHeight: 0, overflow: "hidden" }) : {})
                }}>
                {(isMD || isSM || isXS) &&
                    <CustomSelect
                        options={[{
                            img: "/assets/images/germany.png",
                            value: "de"
                        }, {
                            img: "/assets/images/united-kingdom.png",
                            value: "en"
                        }]}
                        value={selectedLanguage}
                        onChange={(value) => {
                            onLanguageChange(value.value)
                            setSelectedLanguage(value)
                        }}
                    />
                }
                <HeaderLink to="/" onClick={() => handleMenuClose(true)}>
                    <FormattedMessage id="home" />
                </HeaderLink>
                <HeaderLink to="/about-us" onClick={() => handleMenuClose(true)}>
                    <FormattedMessage id="about_us" />
                </HeaderLink>
                <HeaderLink to="/#features" onClick={() => handleMenuClose(window.location.pathname === "/" ? false : true)}>
                    <FormattedMessage id="features" />
                </HeaderLink>
                <HeaderLink to="/#testimonials" onClick={() => handleMenuClose(window.location.pathname === "/" ? false : true)}>
                    <FormattedMessage id="testimonials" />
                </HeaderLink>
                <HeaderLink to="/faq" onClick={() => handleMenuClose(true)}>
                    <FormattedMessage id="faqs" />
                </HeaderLink>
            </div>
            <div style={{
                display: "flex",
                gap: "32px",
                alignItems: "center"
            }}>
                {!(isMD || isSM || isXS) &&
                    <CustomSelect
                        options={[{
                            img: "/assets/images/germany.png",
                            value: "de"
                        }, {
                            img: "/assets/images/united-kingdom.png",
                            value: "en"
                        }]}
                        value={selectedLanguage}
                        onChange={(value) => {
                            onLanguageChange(value.value)
                            setSelectedLanguage(value)
                        }}
                    />
                }
                <button style={{
                    background: "#8C52FF",
                    padding: "16px 24px",
                    textAlign: "center",
                    border: "none",
                    ...((isMD || isSM || isXS) ? { display: "none" } : {})
                }} className={'rounded16 app-button'} >
                    <p style={{ fontSize: "18px", margin: 0, color: "white", userSelect: "none" }}>
                        <FormattedMessage id="download" />
                    </p>
                </button>
            </div>
        </div>
    )
}

const HeaderLink = ({ to, children, onClick }) => {
    const LinkComponent = to.includes("#") ? HashLink : Link
    return (
        <LinkComponent {...(to.includes("#") ? { smooth: true } : {})} to={to} style={{ color: "#000000", fontWeight: "600", textDecoration: "none" }} onClick={onClick}>{children}</LinkComponent>
    )
}

export default Header