import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import StarRatings from 'react-star-ratings';
import { useMediaQuery } from 'react-responsive'
import axios from 'axios';
import formatDate from '../../utils/dateFormatter';
import { FormattedMessage } from 'react-intl';

function ServiceProviderPage({ language }) {
    const { serviceProviderId } = useParams();
    const [serviceProvider, setServiceProvider] = useState(null);

    const [notFound, setNotFound] = useState(false);


    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    useEffect(() => {
        const fetchServiceProvider = async () => {
            axios.get(`${process.env.REACT_APP_API_URL}user/getServiceProviderProfile?userId=${serviceProviderId}&language=${language}&startIndex=0&limit=10`)
                .catch((err) => {
                    console.log("err: " + err);
                    setServiceProvider(null);
                    setNotFound(true);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 200) {
                        setServiceProvider(response.data.user);
                        document.title = response.data.user.name + " " + response.data.user.surname;
                    } else {
                        setNotFound(true);
                    }
                });
        }

        fetchServiceProvider();

    }, [serviceProviderId, language]);

    return (
        <div>
            {serviceProvider &&
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "45px", paddingBottom: "45px" }}>
                    <div style={{ display: "flex", gap: "36px" }}>
                        {(isXL || isLG || isMD) && <img src={serviceProvider.profile_photo_link} alt="service-provider-profile" style={{ borderRadius: "50%", objectFit: "cover", width: "164px", height: "164px" }} />}
                        <div style={{ width: (isXL ? "750px" : isLG ? "600px" : isMD ? "450px" : isSM ? "400px" : "350px") }}>
                            <div style={{ display: "flex", alignItems: "start", justifyContent: (isXS ? "center" : "space-between") }}>
                                <div style={isXS ? {} : { display: "flex", justifyContent: "center", gap: "20px" }}>
                                    {!(isXL || isLG || isMD) &&
                                        <div>
                                            <img src={serviceProvider.profile_photo_link} alt="service-provider-profile" style={{ borderRadius: "50%", objectFit: "cover", width: "164px", height: "164px" }} />
                                        </div>
                                    }
                                    <div>
                                        <p style={{ fontSize: "24px", fontWeight: 800, margin: "0 0 8px 0" }}>{serviceProvider.name} {serviceProvider.surname}{(!!serviceProvider.show_age && serviceProvider.account_type === 0) ? "," : ""} {(!!serviceProvider.show_age && serviceProvider.account_type === 0) && <span style={{ fontSize: "24px", fontWeight: 500 }}>{(new Date()).getFullYear() - (new Date(serviceProvider.birth_date)).getFullYear()}</span>}</p>
                                        <p style={{ fontSize: "16px", fontWeight: 800, margin: "0 0 8px 0" }}>{serviceProvider.county}, {serviceProvider.city}</p>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <StarRatings
                                                rating={parseFloat(serviceProvider.avarage_point.toFixed(1))}
                                                starRatedColor="#DA9207"
                                                numberOfStars={5}
                                                name='rating'
                                                starDimension='24px'
                                                starSpacing='4px'
                                            />
                                            <p style={{ fontSize: "14px", lineHeight: 0, margin: 0, color: "#DA9207", marginLeft: "5px" }}>{serviceProvider.avarage_point.toFixed(1)}</p>
                                            <p style={{ fontSize: "12px", lineHeight: 0, fontWeight: 800, margin: 0 }}>[{serviceProvider.total_review_count}]</p>
                                        </div>
                                        <div style={{ display: "flex", ...(isXS ? { flexDirection: "column" } : {}), alignItems: "center", flexWrap: "wrap", gap: "16px", margin: "19px 0 19px 0" }}>
                                            {serviceProvider.categories?.length > 0 && serviceProvider.categories.map((category, index) => {
                                                return (
                                                    <div key={index} style={{ background: "#F2F0F5", borderRadius: "12px", border: "1px solid #8C52FF", textAlign: "center" }}>
                                                        <p style={{ fontSize: isSM ? "12px" : "15px", margin: "4px 12px" }}>{category.name}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {(isXL || isLG || isMD) &&
                                    <div style={{ background: "#F2F0F5", padding: "4px 20px", borderRadius: "43px", border: "2px solid #8C52FF" }}>
                                        <p style={{ fontSize: "20px", margin: 0, lineHeight: "40px", color: "#8C52FF", textAlign: "center" }}>{serviceProvider.account_type === 1 ? <FormattedMessage id='corporate' /> : <FormattedMessage id='service_provider' />}</p>
                                    </div>
                                }

                            </div>
                            <p style={{ fontSize: "18px", fontWeight: 800, color: "#999999", margin: "0px 0 19px 0" }}>{serviceProvider.bio} </p>
                            {serviceProvider.portfolioItems?.length > 0 &&
                                <div style={{ display: "flex", alignItems: "center", overflowX: "scroll", flexWrap: "nowrap", gap: "20px", margin: "0px 0 19px 0" }}>
                                    {serviceProvider.portfolioItems.map((portfolioItem, index) => {
                                        return <PortfolioItemBox key={index} portfolioItem={portfolioItem} width={"200px"} />
                                    })}
                                    {serviceProvider.portfolioItems.map((portfolioItem, index) => {
                                        return <PortfolioItemBox key={index} portfolioItem={portfolioItem} width={"200px"} />
                                    })}
                                    {serviceProvider.portfolioItems.map((portfolioItem, index) => {
                                        return <PortfolioItemBox key={index} portfolioItem={portfolioItem} width={"200px"} />
                                    })}
                                    {serviceProvider.portfolioItems.map((portfolioItem, index) => {
                                        return <PortfolioItemBox key={index} portfolioItem={portfolioItem} width={"200px"} />
                                    })}
                                    {serviceProvider.portfolioItems.map((portfolioItem, index) => {
                                        return <PortfolioItemBox key={index} portfolioItem={portfolioItem} width={"200px"} />
                                    })}
                                </div>
                            }
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "start" }}>
                                    <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                                        <RatingProgressBar rating={{ relevant_point: <FormattedMessage id='quality' />, percentage: (100 * serviceProvider.avarage_service_quality_point / 5).toFixed(1) }} />
                                        <RatingProgressBar rating={{ relevant_point: <FormattedMessage id='timing' />, percentage: (100 * serviceProvider.avarage_timing_point / 5).toFixed(1) }} />
                                        <RatingProgressBar rating={{ relevant_point: <FormattedMessage id='communication' />, percentage: (100 * serviceProvider.avarage_communication_point / 5).toFixed(1) }} />
                                        <RatingProgressBar rating={{ relevant_point: <FormattedMessage id='pricing' />, percentage: (100 * serviceProvider.avarage_price_point / 5).toFixed(1) }} />
                                    </div>
                                    <div>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                            <p style={{ fontSize: "25px", margin: 0 }}>{serviceProvider.avarage_point.toFixed(1)}</p>
                                            <div style={{ marginTop: "4px" }}>
                                                <StarRatings
                                                    rating={serviceProvider.point > 0 ? 1 : 0}
                                                    starRatedColor="#FFB400"
                                                    numberOfStars={1}
                                                    name='rating'
                                                    starDimension='16px'
                                                    starSpacing='0'
                                                />
                                            </div>
                                        </div>
                                        <p style={{ color: "#9E9E9E", fontSize: "12px", margin: "0", lineHeight: "166%" }}>{serviceProvider.total_review_count}<br /><FormattedMessage id={serviceProvider.total_review_count > 1 ? "review" : "review_pulural"} /></p>
                                    </div>

                                </div>
                            </div>
                            <div>
                                {serviceProvider.reviews && serviceProvider.reviews.length > 0 &&
                                    <>
                                        <p style={{ fontSize: "24px", margin: "44px 0 0 0", fontWeight: 800 }}>
                                            <FormattedMessage id='comments' />
                                        </p>
                                        <div style={{
                                            display: "flex",
                                            rowGap: "44px",
                                            columnGap: "52px",
                                            flexWrap: "wrap",
                                        }}>
                                            {
                                                serviceProvider.reviews.map((c) =>
                                                    <CommentBox key={c.id} comment={c} width={(isXL || isLG) ? "45%" : "100%"} />
                                                )
                                            }
                                        </div>
                                    </>
                                }
                                <div style={{ display: "flex", justifyContent: "center", marginTop: "37px" }}>
                                    <button style={{
                                        background: "#8C52FF",
                                        padding: "16px 0",
                                        width: isSM ? "50%" : isXS ? "60%" : "35%",
                                        textAlign: "center",
                                        border: "none"
                                    }} className={'rounded16 app-button'} >
                                        <p style={{ fontSize: "18px", margin: 0, color: "white", userSelect: "none" }}>
                                            <FormattedMessage id='see_in_app' />
                                        </p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {notFound &&
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "65vh" }}>
                    <h1 style={{ fontSize: "100px", fontWeight: 900, margin: 0, color: "#8C52FF" }}>404</h1>
                    <h2 style={{ fontSize: "24px", fontWeight: 900, margin: 0 }}>
                        <FormattedMessage id='user_not_found' />
                    </h2>
                </div>
            }
        </div>
    )
}


function ProgressBar({ progressStyle, fillerStyle }) {
    return (
        <div style={{ position: "relative", ...progressStyle }}>
            <div style={{
                position: "absolute",
                height: "100%",
                borderRadius: "inherit",
                transition: "width .2s ease-in",
                ...fillerStyle
            }}>
            </div>
        </div>
    )
}

function RatingProgressBar({ rating, style }) {
    return (
        <div style={{ ...style }}>
            <p style={{ position: "absolute", fontSize: "14px", color: "#666666", margin: "0 0 0 0", }}>{rating.relevant_point}</p>
            <ProgressBar
                progressStyle={{
                    width: "200px",
                    height: "8px",
                    background: "#F0F0F0",
                    borderRadius: "2px",
                    marginTop: "5px",
                    marginLeft: "110px"
                }}
                fillerStyle={{
                    width: (rating.percentage) + "%",
                    background: "#FFB400"
                }}
            />
        </div>
    )
}

function CommentBox({ comment, width }) {
    return (
        <div style={{ width: width }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "12px" }}>
                <p style={{ fontSize: "20px", margin: "0" }}>{comment.name + " " + comment.surname}</p>
                <StarRatings
                    rating={comment.avarage_point}
                    starRatedColor="#FFB400"
                    numberOfStars={5}
                    name='rating'
                    starDimension='16px'
                    starSpacing='1px'
                />
            </div>
            <p style={{ fontSize: "16px", color: "#999999", margin: "0 0 6px 0" }}>{comment.comment}</p>
            <p style={{ fontSize: "14px", color: "#999999", margin: "0", textAlign: "end" }}>{formatDate(comment.created_date)}</p>
            <hr />
        </div>
    )
}

function PortfolioItemBox({ portfolioItem, width }) {
    return (
        <div style={{ minWidth: width, maxWidth: width, overflow: "hidden" }}>
            <img src={portfolioItem.content_link} alt={portfolioItem.description} style={{ minWidth: width, maxWidth: width, minHeight: width, maxHeight: width, objectFit: "cover", borderRadius: "16px" }} />
            <p style={{ fontSize: "16px", color: "#999999", margin: "0 0 6px 0", maxHeight: "44px", lineHeight: "22px", textOverflow: "ellipsis", overflow: "hidden", wordWrap: "break-word" }}>{portfolioItem.description}</p>
        </div>
    )
}

export default ServiceProviderPage