import React, { useEffect } from 'react'
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from 'react-responsive'

function UserAgreement({ settings }) {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });
    const aboutUsHtml = settings ? settings.user_agreement : ""

    return (
        <div style={{
            padding: (isSM || isXS) ? "30px 50px 75px 50px" : "30px 180px 75px 180px",
            backgroundColor: "white",
            color: "#000000",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "1.5",
            textAlign: "justify"
        }}>
            <h1>
                <FormattedMessage id='user_agreement' />
            </h1>
            <div dangerouslySetInnerHTML={{ __html: aboutUsHtml }} />
        </div>
    )
}


export default UserAgreement