import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import Carousel from 'react-multi-carousel';
import { FormattedMessage } from 'react-intl';

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

const TESTIMONALS = [
    {
        image: "/assets/images/testimonial-user-1.png",
        name: <FormattedMessage id="testimonials_1_name" />,
        title: <FormattedMessage id="testimonials_1_profession" />,
        point: 5,
        comment: <FormattedMessage id="testimonials_1_comment" />
    },
    {
        image: "/assets/images/testimonial-user-2.png",
        name: <FormattedMessage id="testimonials_2_name" />,
        title: <FormattedMessage id="testimonials_2_profession" />,
        point: 4,
        comment: <FormattedMessage id="testimonials_2_comment" />
    },
    {
        image: "/assets/images/testimonial-user-1.png",
        name: <FormattedMessage id="testimonials_3_name" />,
        title: <FormattedMessage id="testimonials_3_profession" />,
        point: 3,
        comment: <FormattedMessage id="testimonials_3_comment" />
    },
    {
        image: "/assets/images/testimonial-user-2.png",
        name: <FormattedMessage id="testimonials_4_name" />,
        title: <FormattedMessage id="testimonials_4_profession" />,
        point: 5,
        comment: <FormattedMessage id="testimonials_4_comment" />
    },
    {
        image: "/assets/images/testimonial-user-1.png",
        name: <FormattedMessage id="testimonials_5_name" />,
        title: <FormattedMessage id="testimonials_5_profession" />,
        point: 4,
        comment: <FormattedMessage id="testimonials_5_comment" />
    }
]

function MainPage() {
    const isXL = useMediaQuery({ query: '(min-width: 1824px)' });
    const isLG = useMediaQuery({ query: '(min-width: 1224px)' }) && !isXL;
    const isMD = useMediaQuery({ query: '(min-width: 768px)' }) && !isLG && !isXL;
    const isSM = useMediaQuery({ query: '(min-width: 425px)' }) && !isMD && !isLG && !isXL;
    const isXS = useMediaQuery({ query: '(max-width: 425px)' });

    //scroll to top on opening the page
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    padding: "97px 0 97px 0",
                    backgroundImage: "url('assets/images/background.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundAttachment: "fixed",
                    backgroundSize: "cover",
                }}>
                <div style={{ color: "white", width: isXL ? "35%" : isLG ? "45%" : isMD ? "55%" : isSM ? "65%" : "75%" }}>
                    <p style={{ fontSize: "13px", margin: 0, letterSpacing: "1.63px" }}>
                        <FormattedMessage id="from_professionals" />
                    </p>
                    <p style={{ fontSize: "48px", fontWeight: 900, marginTop: "17px", marginBottom: "23px" }}>
                        <FormattedMessage id="home_title" />
                    </p>
                    <p style={{ fontSize: "20px", margin: 0 }}>
                        <FormattedMessage id="home_description" />
                    </p>
                    <div style={{
                        display: "flex",
                        gap: "15px",
                        marginTop: "61px"
                    }}>
                        <img src="/assets/images/google-play-store-badge.png" alt="google-play-store-badge" style={{ height: "50px", objectFit: "contain" }} />
                        <img src="/assets/images/app-store-badge.png" alt="app-store-badge" style={{ height: "50px", objectFit: "contain" }} />
                    </div>
                </div>
                {(isXL || isLG || isMD) && <img src="/assets/images/inapp-screen-shoot.png" alt="service-provider-screen-in-app" height={isMD ? "351px" : "551px"} />}
            </div>
            <div id='features' style={{ paddingTop: "100px", paddingBottom: "100px", display: "flex", flexDirection: "column", alignItems: "center", gap: "32px" }}>
                <h2 style={{ textAlign: "center", fontSize: "28px", fontWeight: 700, color: "#212121", margin: 0 }}>
                    <FormattedMessage id="highlight_features" />
                </h2>
                <div style={{ width: "80px", height: "3px", backgroundColor: "#000000", opacity: "90%" }} />
                <div style={{ display: "flex", flexDirection: (isXL || isLG || isMD) ? "row" : "column", justifyContent: "center", alignItems: "start", gap: "80px", marginTop: "20px" }}>
                    <HighlightFeature image="/assets/images/feature-1.png" title={<FormattedMessage id='highlight_feature_1_title' />} description={<FormattedMessage id='highlight_feature_1_description' />} />
                    <HighlightFeature image="/assets/images/feature-2.png" title={<FormattedMessage id='highlight_feature_2_title' />} description={<FormattedMessage id='highlight_feature_2_description' />} />
                    <HighlightFeature image="/assets/images/feature-3.png" title={<FormattedMessage id='highlight_feature_3_title' />} description={<FormattedMessage id='highlight_feature_3_description' />} />
                </div>
            </div>
            <div id='testimonials' style={{ paddingTop: "100px", paddingBottom: "100px", display: "flex", flexDirection: "column", alignItems: "center", gap: "32px", background: "#f3f3f3" }}>
                <h2 style={{ textAlign: "center", fontSize: "28px", fontWeight: 700, color: "#212121", margin: 0 }}>
                    <FormattedMessage id="testimonials" />
                </h2>
                <div style={{ width: "80px", height: "3px", backgroundColor: "#000000", opacity: "90%" }} />
                <div style={{ marginTop: "20px" }}>
                    <Carousel
                        responsive={responsive}
                        containerClass='carousel-container'
                        itemClass='carousel-item'
                        autoPlay
                        autoPlaySpeed={5000}
                        infinite
                        pauseOnHover
                        dotListClass='react-multi-carousel-list'
                        renderButtonGroupOutside
                    >
                        {TESTIMONALS.map((testimonial, index) => <Testimonal key={index} {...testimonial} />)}
                    </Carousel>
                </div>

            </div>
        </div>
    )
}

function HighlightFeature({ image, title, description }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px", maxWidth: "350px" }}>
            <img src={image} alt={title} style={{ height: "200px", objectFit: "contain" }} />
            <h3 style={{ fontSize: "22px", fontWeight: 700, color: "#000000", marginTop: "30px 0 0 0" }}>{title}</h3>
            <p style={{ fontSize: "16px", color: "#000000", textAlign: "center", margin: 0 }}>{description}</p>
        </div>
    )
}

function Testimonal({ image, name, title, point = 5, comment }) {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            paddingRight: "30px",
        }}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <img src={image} alt={name} style={{ height: "90px", objectFit: "contain" }} />
                <div>
                    <p style={{ fontSize: "16px", fontWeight: 500, color: "#2D2E2E", margin: 0 }}>{name}</p>
                    <p style={{ fontSize: "12px", fontWeight: 500, color: "#ABABAB", marginTop: "4px 0 0 0" }}>{title}</p>
                    <div style={{ display: "flex", gap: "5px", marginTop: "12px" }}>
                        {Array.from({ length: point }, (_, index) => <img key={index} src="/assets/images/star.png" alt="star" style={{ height: "24px", objectFit: "contain" }} />)}
                        {Array.from({ length: 5 - point }, (_, index) => <div key={index} style={{ width: "24px", height: "24px" }} />)}
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "start", gap: "16px", marginTop: "24px" }}>
                <img src="/assets/images/testimonial-quotation-mark.png" alt="testimonial-quotation-mark" style={{ height: "24px", objectFit: "contain" }} />
                <p style={{ fontSize: "16px", color: "#2D2E2E", fontWeight: 500, margin: 0 }}>{comment}</p>
            </div>
        </div>
    )
}

export default MainPage